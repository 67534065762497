import { EventEmitter, Injectable, Output, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ANALYTICS_CONSTS,
  APP_STATE,
  AuthLoad,
  GthAnalyticsService,
  GthAuthService,
} from '@gth-legacy';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { UserService } from '@sentinels/services/firebase/user.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class UserAvatarUpdateService {
  private userSubject = new BehaviorSubject<GthUserModel | null>(null);
  loading = signal<boolean>(false);

  constructor(
    private auth: GthAuthService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private analytics: GthAnalyticsService,
    private store: Store<APP_STATE>,
  ) { }

  getCurrentUser$() {
    return this.userSubject.asObservable();
  }

  @Output()
  moreInfoComplete = new EventEmitter<GthUserModel>();

  async setChangedInfo(data: GthUserModel) {
    this.loading.set(true);
    const user = await firstValueFrom(this.auth.getCurrentUser$());

    if (user) {
      await this.userService.updateUser(data).then(
        (success) => {
          if (success) {
            this.snackbar.open('Profile Updated');
            this.analytics.logEvent(ANALYTICS_CONSTS.settingsUpdated, {
              context: 'more-info',
            });
          }
          this.loading.set(false);
        },
      );
    } else {
      this.snackbar.open('Must be logged in to change');
      this.loading.set(false);
    }

    this.store.dispatch(AuthLoad());
    this.moreInfoComplete.emit(data);
    this.loading.set(false);
  }
}
